$(document).ready(function () {

    /**
     * Resets confirm button in 'confirm' state back to original state.
     *
     * @param $button
     */
    function resetPWIConfirmButton($button) {
        let originalHTML = $button.attr('data-original-html');

        // Reset the button's HTML
        $button.html(originalHTML);
        $button.removeClass('confirm-state');
        $button.removeAttr('data-original-html');
        $button.removeClass('disabled');
        $button.removeAttr('disabled');

        // Remove inline CSS props
        $button.css('text-align', '');
        $button.css('min-width', '');
        $button.css('height', '');
    }

    /**
     * User initially clicks PWI Confirm Button.
     */
    $(document).on('click', '.pwi-confirm-button:not(.confirm-state)', function(event) {
        event.stopPropagation();

        // Remove confirm state from all other confirm buttons
        $('.pwi-confirm-button.confirm-state').each(function (index, element) {
            resetPWIConfirmButton($(element));
        });

        // Variables
        let $button      = $(event.currentTarget);
        let buttonWidth  = $button.outerWidth();
        let buttonHeight = $button.outerHeight();

        // Store the button's original HTML content
        $button.attr('data-original-html', $button.html());

        // Maintain the button's original proportions
        $button.css('text-align', 'center');
        $button.css('min-width', buttonWidth);
        $button.css('height', buttonHeight);

        // Ask the user for confirmation
        $button.html('Confirm?');
        $button.addClass('confirm-state');
    });

    /**
     * User click the PWI Confirm button in confirmation state.
     */
    $(document).on('click', '.pwi-confirm-button.confirm-state', function(event) {
        event.stopPropagation();

        // Variables
        let $button= $(event.currentTarget);
        let callbackName= $button.attr('data-callback');
        let callbackArg     = $button.attr('data-callback-arg');

        // Update the button HTML
        $button.html('<i class="fa fa-cog fa-spin"></i>');
        $button.addClass('disabled');
        $button.attr('disabled', 'disabled');

        // Call the callback function
        if (callbackArg) {
            window[callbackName](callbackArg, function () {
                // If the function call works, then reset the button
                resetPWIConfirmButton($button);
            });
        } else {
            window[callbackName](function () {
                // If the function call works, then reset the button
                resetPWIConfirmButton($button);
            });
        }
    });

});
